import React from 'react';
import './DropZone.css';

class DropZone extends React.Component {
  isAdvancedUpload = function() {
    var div = document.createElement('div');
    return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window;
  }();
  suppress = (e) => { e.preventDefault(); e.stopPropagation() };

  state = {
    dragOver: false,
    webKitDirectorySupport: 'webkitdirectory' in document.createElement('input')
  }

  dragOver(e) {
    this.suppress(e);
    this.setState({dragOver: true});
  }

  dragOut(e) {
    this.suppress(e);
    this.setState({dragOver: false});
  }

  async drop(e) {
    this.dragOut(e);
    const droppedFiles = await getAllFileEntries(e.dataTransfer.items);

    this.props.itemsDropped(Array.from(droppedFiles));
  }

  chooseFiles(files) {
    let filesToUpdate = Array.from(files);
     for (let i = 0; i < filesToUpdate.length; i++) {
      filesToUpdate[i].fullPath = filesToUpdate[i].webkitRelativePath;
    }
    this.props.itemsDropped(filesToUpdate);
  }

  getMessage()
  {
    if (!this.props.dropZoneActive)
    {
      return (<span>Wait for connection to upload files</span>)
    }

    return (this.state.webKitDirectorySupport ? <><a>Choose directory</a><span className="box__dragndrop"> or drag files here</span></> : <span>Drag files here</span>)
  }

  render() {
    return (
      <div className={"dropzone" + (!this.props.dropZoneActive ? ' dropzone-disabled' : '')}>
        <div
          className={"box" + (this.isAdvancedUpload ? ' has-advanced-upload':'') + (this.state.dragOver ? ' is-dragover' : '')}
          onDrag={this.suppress} onDragStart={this.suppress} onDragOver={(e) => this.dragOver(e)} onDragEnter={(e) => this.dragOver(e)} onDragLeave={(e) => this.dragOut(e)} onDragEnd={(e) => this.dragOut(e)}  onDrop={(e) => this.drop(e)}>
          <div className="box__input">
            {/*<svg className="box__icon" xmlns="http://www.w3.org/2000/svg" width="50" height="43" viewBox="0 0 50 43"><path d="M48.4 26.5c-.9 0-1.7.7-1.7 1.7v11.6h-43.3v-11.6c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v13.2c0 .9.7 1.7 1.7 1.7h46.7c.9 0 1.7-.7 1.7-1.7v-13.2c0-1-.7-1.7-1.7-1.7zm-24.5 6.1c.3.3.8.5 1.2.5.4 0 .9-.2 1.2-.5l10-11.6c.7-.7.7-1.7 0-2.4s-1.7-.7-2.4 0l-7.1 8.3v-25.3c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v25.3l-7.1-8.3c-.7-.7-1.7-.7-2.4 0s-.7 1.7 0 2.4l10 11.6z"></path></svg>*/}
            <input className="box__file" type="file" name="files[]" id="file" data-multiple-caption="{count} files selected" onChange={(e) => this.chooseFiles(e.target.files)} webkitdirectory="" multiple />
            <p><label htmlFor="file">{this.getMessage()}.</label></p>
          </div>
        </div>
      </div>
    );
  }
}

// Drop handler function to get all files
async function getAllFileEntries(dataTransferItemList) {
  let fileEntries = [];
  // Use BFS to traverse entire directory/file structure
  let queue = [];
  // Unfortunately dataTransferItemList is not iterable i.e. no forEach
  for (let i = 0; i < dataTransferItemList.length; i++) {
    queue.push(dataTransferItemList[i].webkitGetAsEntry());
  }
  while (queue.length > 0) {
    let entry = queue.shift();
    if (entry.isFile) {
      // fileEntries.push(entry);
      const file = await getFile(entry);
      file.fullPath = entry.fullPath.slice(1);
      console.log(file);
      fileEntries.push(file);
    } else if (entry.isDirectory) {
      let reader = entry.createReader();
      queue.push(...await readAllDirectoryEntries(reader));
    }
  }
  return fileEntries;
}

// Get all the entries (files or sub-directories) in a directory by calling readEntries until it returns empty array
async function readAllDirectoryEntries(directoryReader) {
  let entries = [];
  let readEntries = await readEntriesPromise(directoryReader);
  while (readEntries.length > 0) {
    entries.push(...readEntries);
    readEntries = await readEntriesPromise(directoryReader);
  }
  return entries;
}

async function getFile(file) {
  try {
    return await new Promise((resolve, reject) => {
      file.file(resolve, reject);
    });
  } catch (err) {
    console.log(err);
  }
}

// Wrap readEntries in a promise to make working with readEntries easier
async function readEntriesPromise(directoryReader) {
  try {
    return await new Promise((resolve, reject) => {
      directoryReader.readEntries(resolve, reject);
    });
  } catch (err) {
    console.log(err);
  }
}

export default DropZone;