import React from 'react';

class LoginDialog extends React.Component {

  state = {
    username: '',
    password: ''
  }

  render() {
    return (
      <div className="login">
        <img src="cloud.png" />
        {this.props.loginError && <p className="error">
          {this.props.loginError}
        </p>}
        <form onSubmit={e => {e.preventDefault(); this.props.onLogin(this.state.username, this.state.password)}}>
          <input id="email" name="email" placeholder="Email" value={this.state.username} onInput={e => this.setState({username: e.target.value})} type="email" /><br />
          <input id="password" name="password" placeholder="Password" value={this.state.password} onInput={e => this.setState({password: e.target.value})} type="password" />
          <button style={{display:'block', margin: '2em auto 1em auto'}} type="submit" name="action">
            Login
          </button>
        </form>
      </div>
    );
  }
}

export default LoginDialog;