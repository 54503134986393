
import React from 'react';
const uuid = require('uuid').v4;

class DeviceEmulator extends React.Component {
  state = {
    messages: [],
    acceptLicenses: false,
    licenses: [
      ["Medical Imaging XR","dicomviewer"],
      ["Anatomy Master 2","anatomymaster"],
      ["Dissection Master XR","dissectionmaster"]].map(([l, c]) => {
      return {
        productName: c,
        displayName: l,
        expiryDate: null,
        active: false,
        description: l
      }
    })
  };

  addMessage (msg) {
    this.state.messages.push(msg);
    this.setState({messages: this.state.messages});
  }

  componentWillMount() {
    const { deviceLinkSocket } = this.props;
    const code = "121312";
    const id = "695c572c-5504-400d-895c-85ff33851177";

    deviceLinkSocket.on('connect', () => {
      this.addMessage("Connected");
      const identMessage = {
        "label": "Device Emulator",
        "id": id,
        "type": "standalone",
        "code": code
      };
      deviceLinkSocket.emit("set_device_identity", identMessage);
      this.addMessage(`> set_device_identity ${JSON.stringify(identMessage, null, 2)}`);
    });

    deviceLinkSocket.on('link_message', ({from, msg}) => {
      this.addMessage(`< link_message ${JSON.stringify(msg, null, 2)}`);
      switch (msg.type) {
        case 'get_license_status':
          const licenseMessage = {
            to: from,
            msg: {
              type: 'licenses',
              licenses: this.state.licenses
            }
          };
          deviceLinkSocket.emit("link_message", licenseMessage);
          this.addMessage(`> link_message ${JSON.stringify(licenseMessage, null, 2)}`);
          break;
        case 'activate_product':
          const license = this.state.licenses.find(c => c.productName == msg.product);
          if (license) {
            if (this.state.acceptLicenses) {
              license.active = true;
              license.expiryDate = "2025/10/03 14:12";
              this.setState({
                licenses: this.state.licenses
              }, () => {
                const licenseMessage = {
                  to: from,
                  msg: {
                    type: 'licenses',
                    licenses: this.state.licenses
                  }
                };
                deviceLinkSocket.emit("link_message", licenseMessage);
                this.addMessage(`> link_message ${JSON.stringify(licenseMessage, null, 2)}`);      
              });
            }
          }
          break;
      }
    });
  }
  
  render() {
    return <div style={{display:'flex', boxSizing: 'border-box', flexDirection:'column', maxHeight:'100vh', padding: '0.5em'}}>
      <div style={{display:'block', userSelect: 'none', height:'2em'}}>
        <label>
          <input style={{display:'inline-block', width:'auto'}}
            type="checkbox"
            onChange={(e) => this.setState({acceptLicenses: !this.state.acceptLicenses})}
            checked={this.state.acceptLicenses} />
          Accept all license keys
        </label>
      </div>
      <div style={{overflow: 'auto', flex: 1}}>
        {this.state.messages.map((m, ix) => <pre key={ix} style={{padding: '0.5em 0', margin: '0', borderTop: '1px solid #ccc'}}>{m}</pre>)}
      </div>
    </div>
  }
}

export default DeviceEmulator;