import React from 'react';

class Support extends React.Component {

  render() {
  
    return (
      <div>
          <p>Contact <a href="mailto:info@medicalholodeck.com">info@medicalholodeck.com</a> for support.</p>
          <h2>Licenses</h2>
          <p>Buy your licenses online on <a href="https://medicalholodeck.com">medicalholodeck.com</a> or contact us on <a href="mailto:info@medicalholodeck.com">info@medicalholodeck.com</a> for more information and special deals.</p>
          <h2>Tutorials</h2>
          <p>Check our tutorials on <a href="https://www.medicalholodeck.com/en/support/tutorials-manuals/">medicalholodeck.com</a>.</p>
      </div>
    )
  }
}

export default Support;