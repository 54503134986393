import React from 'react';
import filesize from 'filesize';
import humanizeDuration from 'humanize-duration';
import './Upload.css';
import ProcessingCube from './ProcessingCube';
import Visualizer from './Visualizer';

function Upload(props) {
  const { percentage, transferred, length, speed, eta } = props.progress;
  const humanizeDurationOptions = {
    languages: {
      shortEn: {
        y: () => 'y',
        mo: () => 'mo',
        w: () => 'w',
        d: () => 'd',
        h: () => 'h',
        m: () => 'm',
        s: () => 's',
        ms: () => 'ms',
      }
    },
    round: true, largest: 2, language: 'shortEn', spacer: '', delimiter: ' '
  }

  const getEstimatedTimeClassName = () => {
    return (!eta || isNaN(eta) || !isFinite(eta)) ? 'displayNone' : '';
  }

  return (
    <div className="upload">
      <Visualizer setId={props.id} set={props.set}/>
      <div>
        <h3>{(typeof props.set.set === 'string' && props.set.set.startsWith('recordxr')) ? 'RecordXR' : (typeof props.set.set === 'string' && props.set.set.startsWith('mhfile-modelState')) ? 'ModelState' : (typeof props.set.set === 'string' && props.set.set.startsWith('mhfile-preset')) ? 'Preset' : (typeof props.set.set === 'string' && props.set.set.startsWith('image')) ? 'Image' : ((typeof props.set.set === 'string' && props.set.set.startsWith('document')) ? 'Document': (props.set.obj ? "Textured mesh" : ( props.set.meshData ? "Mesh" : `${props.set.length} slice${props.set.length === 1 ? '' : 's'}`)))}</h3>
        <p className='id' title={props.id}>{[props.set.metaData.BodyPart, props.set.metaData.Modality].filter(d => !!d).join(" – ")}</p>
      </div>
      <div style={{margin:'1em 0'}}>
        {(!props.uploadState || props.uploadState === 'none') && <span> Preparing</span>}
          {(props.uploadState === 'uploading') && <span> Uploading</span>}
          {(props.uploadState === 'importAccept') && <span> Waiting for import</span>}
          {(props.uploadState === 'processing') && <span> Processing</span>}
          {(props.uploadState === 'done') && <span> Done</span>}
          {(props.uploadState === 'error') && <span> Something went wrong</span>}
          {(props.uploadState === 'cancelled') && <span> Import cancelled</span>}
      </div>
      <div style={{width: '100%'}}>
        {props.uploadState === 'uploading' && props.progress != {} && <div className="progress" style={{width: '90%'}}><div className="progresscontainer">
            <div className="progressbar" style={{width: parseInt(percentage || 0, 10) + "%"}}></div>
            <div className="progresstext">{ filesize(transferred || 0) }/{ filesize(length || 0) }<br/>{ filesize(speed || 0) }/s </div>
          </div>
            <div className={getEstimatedTimeClassName()}>{humanizeDuration(eta * 1000, humanizeDurationOptions) || 0 }</div>
        </div>
        }
        {(props.uploadState === 'error') && <p><code>{props.error}</code></p>}
        {(props.uploadState === 'done') && props.mobile && <div style={{textAlign:'center', marginBottom:'1em'}}>
          {!props.linkedMhdClient && <button onClick={props.onOpenClick}>Open in mobile App</button>}
          {props.linkedMhdClient && props.uploadState == 'done' && 
            <div>
              <div className="link-success" style={{fontSize: '100%'}}><img src="check-circle-solid.svg" style={{marginBottom:'0.55em'}} /> Sent to {props.linkedMhdClient.name.replace('mhd-client', '')}</div>
              <a href="#" onClick={props.onOpenClick}>Show mobile QR code</a>
            </div>}
        </div>}
      </div>
    </div>
  );
}

export default Upload;