import React from 'react';
import './licenses.css';

// sort licenses by MIXR, DIXR, AMXR if possible
const sortTable = {'dicomviewer': 100, 'anatomymaster': 0, 'dissectionmaster': 50};

class LicenseManager extends React.Component {
  state = {
    licenses: []
  }

  componentWillMount() {
    this.processLinkMessage = this.processLinkMessage.bind(this);

    this.props.deviceLinkSocket.on('link_message', this.processLinkMessage);
    this.updateLicenses();
  }

  componentDidUpdate(pProps) {
    if ((this.props.device.online && !pProps.device.online) || (this.props.device && (!pProps.device || pProps.device.id !== this.props.device.id))) {
      this.updateLicenses();
    }
  }

  updateLicenses() {
    // update licenses
    this.props.deviceLinkSocket.emit('link_message', {
      token: this.props.device.token,
      msg: {
        type: 'get_license_status'
      }
    });
  }

  componentWillUnmount() {
    this.props.deviceLinkSocket.off('link_message', this.processLinkMessage);
  }

  processLinkMessage({from, msg}) {
    if (from === this.props.device.id && "type" in msg && msg.type === 'licenses') {
      this.setState({licenses: msg.licenses});
    }
  }

  activateLicense(license, key) {
    this.props.deviceLinkSocket.emit('link_message', {
      token: this.props.device.token,
      msg: {
        type: 'activate_product',
        product: license.productName,
        key: key
      }
    });
  }

  submitLicenseForm(license, e) {
    e.preventDefault();

    const input = e.currentTarget.querySelector('input');
    const key = input.value.trim();

    if (key.length != "XXXXXX-XXXXXX-XXXXXX-XXXXXX-XXXXXX".length) {
      window.alert("Please enter a license key in the form\nXXXXXX-XXXXXX-XXXXXX-XXXXXX-XXXXXX");
      return;
    }
    input.value = '';
    this.activateLicense(license, key);

    return false;
  }

  render() {
    const licenses = (this.state.licenses || []).sort((a,b) => {
      return sortTable[b.productName] - sortTable[a.productName];
    }).map(license => {
      return <div key={license.productName} className={(license.active ? ' license-active' : ' license-inactive')}>
        <h3 style={{fontWeight:'bold'}}>{license.displayName}</h3>
        <p className={"license-status"}>
          <span className="license-badge">{license.active ? "License active" : "License required"}</span> {license.active && license.expiryDate ? 'until ' + license.expiryDate + '' : ""}
        </p>
        <form style={{display:'block'}} onSubmit={(e) => this.submitLicenseForm(license, e)}>
          <input type="text" placeholder="XXXXXX-XXXXXX-XXXXXX-XXXXXX-XXXXXX" />
          <button type="submit">Activate License Key</button>
        </form>
      </div>
    });

    return (
      <div className="licenses">
        {
          !this.props.device.online &&
            'Your device is offline. Please run Medicalholodeck on your device.'
        }
        { this.props.device.online &&
            licenses }
      </div>
    );
  }
}

export default LicenseManager;
