import React, { useEffect, useState } from 'react';

import './XRRecordingManager.css';
export default function XRRecordingManager(props) {

  const [XRRecordings, setXRRecordings] = useState([]);
  const [editedSets, setEditedSets] = useState({});
  const [previews, setPreviews] = useState({});
  const [paths, setPaths] = useState({});
  const [preventChange, setPreventChange] = useState(false);

  useEffect(() => {
      props.deviceLinkSocket.on('link_message', processLinkMessage);
      updateXRRecordings();

      return () => {
          props.deviceLinkSocket.off('link_message', processLinkMessage);
      }
  }, [])

  useEffect(() => {
    updateXRRecordings();
  }, [props])


  function updateXRRecordings() {
      setPreviews({});
      props.deviceLinkSocket.emit('link_message', {
          token: props.device.token,
          msg: {
                  type: 'get_xrrecordings'
          }
      });
  }

  function processLinkMessage({from, msg}) {
    if (from !== props.device.id || !("type" in msg)) return;
    
    if (msg.type === 'xrrecordings') {
      setXRRecordings(msg.recordings);
      const structurePath = 'test;test1;test2';
      const setsDescriptionsValue = {};
      const recordingsToSave = [];
      msg.recordings.forEach((element, index) => {
        recordingsToSave.push(element);
        recordingsToSave[index].StructurePath = structurePath;
        setsDescriptionsValue[element.Id] = { Description: element.Description }
        deseralizePath(structurePath, [], element.Id);
      });
      setXRRecordings(recordingsToSave);
      setEditedSets(setsDescriptionsValue);


      if (Object.keys(previews).length == 0) {
        props.deviceLinkSocket.emit('link_message', {
          token: props.device.token,
          msg: {
            type: 'get_xrrecording_previews'
          }
        });
      }
    }
    else if (msg.type === 'xrrecording-previews') {
      setPreviews(msg.previews);
    }
  }

  function setXRRecording(recordingID) {
    props.deviceLinkSocket.emit('link_message', {
      token: props.device.token,
      msg: {
        type: 'set_xrrecording',
        xrrecording: Object.assign({Id: recordingID}, editedSets[recordingID] || {})
      }
    });
  }

  function deleteXRRecording(recordingID) {
    props.deviceLinkSocket.emit('link_message', {
      token: props.device.token,
      msg: {
        type: 'delete_xrrecording',
        xrrecording: {Id: recordingID}
      }
    });
  }

  function saveEdit(xrrecording) {
    setXRRecording(xrrecording.Id);
  } 

  function deseralizePath(itemDirectory, pathArray, recordingID) {
    if (pathArray.length === 0 && itemDirectory === '') {
      setPaths(oldPaths => {
        let prev = {...oldPaths};
        prev[recordingID] = [''];
        return prev;
      })
    }else {
      let firstSeparatorIndex = itemDirectory.indexOf(';');
      let pathElement;
      if (firstSeparatorIndex < 0) {
        pathElement = itemDirectory;
      }else {
        pathElement = itemDirectory.substring(0, firstSeparatorIndex);
      }
      if (pathElement !== '') {
        if (pathArray.length === 0 && firstSeparatorIndex < 0) {
          pathArray = [pathElement, ''];
        }else if (pathArray.length === 0 && firstSeparatorIndex >= 0) {
          pathArray = new Array(pathElement);
        }else if (firstSeparatorIndex >= 0) {
          pathArray = [...pathArray, pathElement];
        }else {
          pathArray = [...pathArray, pathElement, ''];
        }
      } 
      if (firstSeparatorIndex >= 0) {
        deseralizePath(itemDirectory.substring(firstSeparatorIndex+1), pathArray, recordingID);
      }else {
        setPaths(oldPaths => {
          let prev = {...oldPaths};
          prev[recordingID] = pathArray;
          return prev;
        })
      }
    }
  }

  function serializePath(pathArray) {
    return pathArray.join(';').replace(/^;+/, '').replace(/;+$/, '');
  }

  function setName(recordingID, name) {
    setEditedSets((prev) => {
      let prevEditedSets = {...prev} || {};
      prevEditedSets[recordingID].Description = name;
      return prevEditedSets;
    });
  }


  function setPathDirectory(recordingID, index, value) {
    if (preventChange) {
      setPreventChange(false);
    }else {
      if (value.includes(';')) {
        let pathsToSerialize = paths[recordingID];
        pathsToSerialize[index] = value;
        deseralizePath(serializePath(pathsToSerialize), [], recordingID);
      }else {
        setPaths(oldPaths => {
          let prevPaths = {...oldPaths};
          if (prevPaths[recordingID][index] === '' && value !== '' && index === prevPaths[recordingID].length - 1) {
            prevPaths[recordingID].push('');
          } 
          prevPaths[recordingID][index] = value;
          return prevPaths;
        })
      }
    }
  }

  function savePath(index, path) {
    //Add socket emit
    setXRRecordings(oldRecs => {
      let prevRecs = [...oldRecs];
      prevRecs[index].StructurePath = path;
      return prevRecs; 
    })
  }

  function keyPressHandlerUp(key, recordingID, value, index) {
    if (key === "Enter" && paths[recordingID].length-1 !== index) {
      document.getElementById(recordingID+Number(index+1)).focus();
    }else if (key === "Delete") {
      let pathsToSerialize = paths[recordingID];
      pathsToSerialize[index] = '';
      deseralizePath(serializePath(pathsToSerialize), [], recordingID);
    }
  }

  function keyPressHandlerDown(key, recordingID, value, index) {
    if (key === "Backspace") {
      if  (paths[recordingID][index] === '' && value === ''){
        deseralizePath(serializePath(paths[recordingID]), [], recordingID);
        setPreventChange(true);
        if (index > 0) document.getElementById(recordingID+Number(index-1)).focus();
      }
    }
  }

  return <div>
      {
          !props.device.online &&
              'Your device is offline. Please run Medicalholodeck on your device.'
      }
      { 
          props.device.online && editedSets &&
          (XRRecordings || []).map((XRRecording, recordingIndex) => {
            let editedSetsLocal = editedSets || {};
            let label = editedSetsLocal[XRRecording.Id] !== undefined ? editedSetsLocal[XRRecording.Id].Description : XRRecording.Description;
            let shownDescription = XRRecording.Id in editedSetsLocal ? editedSetsLocal[XRRecording.Id].Description : (XRRecording.Description || "");
            let path = paths[XRRecording.Id] || [''];
            return <div className="recordXR title-panel" key={XRRecording.Id}>
              <div className="data-preview-image" style={{
                backgroundImage: `url(data:image/png;base64,${previews[XRRecording.Id]})`}}>
              </div>
              <div className='metadata'>
                <h3>{(XRRecording.Description || "") === "" ? `#${XRRecording.RecordingIndex}` : XRRecording.Description}</h3>
                { new Date(Number(XRRecording.Time)*1000).toLocaleDateString() }
                <br />
                { Math.floor(XRRecording.Duration/60) < 10 ? '0' + Math.floor(XRRecording.Duration/60) : Math.floor(XRRecording.Duration/60)}:{ Math.floor(XRRecording.Duration%60) < 10 ? '0' + Math.floor(XRRecording.Duration%60) : Math.floor(XRRecording.Duration%60)}
              </div>
{/*               <div className="inputPath">
                <label>Path</label>
                <div style={{ display: 'block', marginTop: '5px'}}>
                  <div style={{ float: 'left', padding: '5px 0 5px 5px', height: '16px'}}>/</div>
                </div>
                {path.map((pathElement, index) => {
                  return <div className="pathElement" key={XRRecording.Id + index}>
                    <input id={XRRecording.Id + index} style={{width: (pathElement.length + 1) + 'ch'}} type="text" value={pathElement} 
                      onChange={(e) => setPathDirectory(XRRecording.Id, index, e.target.value)} onKeyUp={(e) => { keyPressHandlerUp(e.key, XRRecording.Id, e.target.value, index); }} onKeyDown={(e) => { keyPressHandlerDown(e.key, XRRecording.Id, e.target.value, index); }}/>
                    <div style={{ color: index === path.length-1 ? '#80808075' : 'black', paddingRight: index === path.length-1 ? '5px' : '0'}}>/</div>
                  </div>
                })}
                {serializePath(path) !== XRRecording.StructurePath && 
                <div className="pathButtons">
                  <button onClick={e => savePath(recordingIndex, serializePath(path))}>Save</button>
                  <button onClick={e => deseralizePath(XRRecording.StructurePath, [], XRRecording.Id)}>Cancel</button>
                </div>}
              </div> */}
              <div className="input">
                <label>Label</label><input style={{maxWidth:'300px'}} type="text" value={ label} onChange={e => setName(XRRecording.Id, e.target.value)} />
                {shownDescription != XRRecording.Description && <button onClick={() => saveEdit(XRRecording)}>Save</button>}
                {shownDescription != XRRecording.Description && <button onClick={() => setName(XRRecording.Id, XRRecording.Description)}>Cancel</button>}
              </div>

              <div className="delete">
                <button onClick={() => deleteXRRecording(XRRecording.Id)}>Delete</button>
              </div>
            </div>
          }).reverse()
      }
  </div>
} 