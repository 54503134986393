import React from 'react';
import { ReactComponent as CloudIcon } from './device-link.svg';

class DeviceLinkDialog extends React.Component {

  state = {
    code: [
      '',
      '',
      '',
      '',
      '',
      ''
    ]
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.deviceLinkVerifying && !this.props.deviceLinkVerifying && this.state.code.every(x => x)) {
      this['digit' + 5].focus();
    }
  }

  setDigit(value, ix) {
    const code = this.state.code.slice(0);

    if (value === 'Backspace') {
      if (this['digit' + (ix - 1)]) {
        this['digit' + (ix - 1)].focus();
      }

      code[ix] = '';
      this.setState({code});
      return;
    }

    if (!/^[0-9]$/g.test(value)) return;
    
    code[ix] = value;

    this.setState({code});

    if (this['digit' + (ix)]) this['digit' + (ix)].blur();
    if (this['digit' + (ix + 1)]) {
      this['digit' + (ix + 1)].focus();
    }
    
    if (code.every(x => x)) this.props.onEnter(code.join(""));
  }

  componentDidMount() { 
    if (this.props.currentCode) {
      const code = this.state.code.slice(0);
      this.props.currentCode.split("").forEach((v, ix) => {
        code[ix] = v;
      })
      this.setState({code});
    }
  }

  render() {
    let code = this.state.code;

    return (
      <div className="devicelink">
        <div>
          <CloudIcon className="cloudicon" style={{
            height:'10vh', minHeight: '5em', maxHeight: '7em', marginBottom:'0.55em'}} />
          <br/>
          <h1 style={{marginBottom:'0.25em'}}>Device Link</h1>
          {!this.props.linkedMhdClient && <div>
            <p>Enter the 6-digit code to connect a device:</p>
            <div className="inputs">
              {[code[0]||'',code[1]||'',code[2]||'',code[3]||'',code[4]||'',code[5]||''].map((v, ix) =>
                <input key={'code'+ix} onFocus={e => e.target.select()} ref={(input) => { this['digit' + ix] = input; }} type="text" maxLength="1" onChange={() => {}} value={v} onKeyDown={e => {this.setDigit(e.key, ix);}} />
              )}
            </div>
            {this.props.deviceLinkVerifying && <div className="loader"></div>}
            {!this.props.deviceLinkVerifying && code.every(x => x) && <p>We could not connect you to a device using this code. Please verify that all digits match.</p>}
          </div>}
          {this.props.linkedMhdClient && <div>
            <div className="link-success"><img src="check-circle-solid.svg" style={{marginBottom:'0.55em'}} /> Device is linked ({this.props.linkedMhdClient.name.replace('mhd-client', '')})</div>
            <p>All datasets will appear in your linked device.</p>
            <p style={{marginTop:'3em'}}><button onClick={() => this.props.onClose()}>Back to Uploads</button>
            <br/><a style={{marginTop:'0.75em', display:'inline-block'}} href="#" onClick={() => { this.setState({code: ''}); this.props.unlink()}}>Link another device</a></p>
          </div>}
        </div>
        <div>
          <h2>Need help?</h2>
          <h3>Watch the <a href="https://youtu.be/AH1YwePcfYQ" target="_blank">tutorial</a> or <a href="mailto:info@medicalholodeck.com">contact us</a>.</h3>
        </div>
      </div>
    );
  }
}

export default DeviceLinkDialog;