import React, { useEffect, useState } from 'react';

export default function PresetManager(props) {

  const [Presets, setPresets] = useState([]);
  const [editedSets, setEditedSets] = useState({});
  const [previews, setPreviews] = useState({});

  useEffect(() => {
      props.deviceLinkSocket.on('link_message', processLinkMessage);
      updatePresets();

      return () => {
          props.deviceLinkSocket.off('link_message', processLinkMessage);
      }
  }, [])

  useEffect(() => {
    updatePresets();
  }, [props])


  function updatePresets() {
      setPreviews({});
      props.deviceLinkSocket.emit('link_message', {
          token: props.device.token,
          msg: {
                  type: 'get_presets'
          }
      });
  }
  function processLinkMessage({from, msg}) {
    if (from !== props.device.id || !("type" in msg)) return;
    
    if (msg.type === 'presets') {
      setPresets(msg.presets);
      const setsLabelValue = {};
      const presetsToSave = [];
      msg.presets.forEach((element) => {
        presetsToSave.push(element);
        setsLabelValue[element.Guid] = { Label: element.Label }
      });
      setPresets(presetsToSave);
      setEditedSets(setsLabelValue);
      if (Object.keys(previews).length == 0) {
        props.deviceLinkSocket.emit('link_message', {
          token: props.device.token,
          msg: {
            type: 'get_preset_previews'
          }
        });
      }
    }
    else if (msg.type === 'preset-previews') {
      setPreviews(msg.previews);
    }
  }

  function setpreset(Guid, Label) {
    props.deviceLinkSocket.emit('link_message', {
      token: props.device.token,
      msg: {
        type: 'set_preset',
        preset: Object.assign({Guid: Guid, Label: Label}, editedSets[Guid] || {})
      }
    });
  }

  function deletepreset(presetGuid) {
    props.deviceLinkSocket.emit('link_message', {
      token: props.device.token,
      msg: {
        type: 'delete_preset',
        preset: {Guid: presetGuid}
      }
    });
  }

  function saveEdit(preset) {
    setpreset(preset.Guid, preset.Label);
  } 

  function setName(guid, name) {
    setEditedSets((prev) => {
      let prevEditedSets = {...prev} || {};
      prevEditedSets[guid].Label = name;
      return prevEditedSets;
    });
  }

  return <div>
      {
          !props.device.online &&
              'Your device is offline. Please run Medicalholodeck on your device.'
      }
      { 
          props.device.online && editedSets &&
          (Presets || []).map((preset, presetIndex) => {
            let editedSetsLocal = editedSets || {};
            let label = editedSetsLocal[preset.Guid] !== undefined ? editedSetsLocal[preset.Guid].Label : preset.Label;
            let shownDescription = preset.Guid in editedSetsLocal ? editedSetsLocal[preset.Guid].Label : (preset.Label || "");
            return <div className="dataset title-panel" key={preset.Guid}>
              <div className="data-preview-image" style={{
                backgroundImage: `url(data:image/png;base64,${previews[preset.Guid]})`}}>
              </div>
              <h3>{preset.Label}</h3>
              <div className="input">
                <label>Label</label><input style={{maxWidth:'300px'}} type="text" value={ label} onChange={e => setName(preset.Guid, e.target.value)} />
                {shownDescription != preset.Label && <button onClick={() => saveEdit(preset)}>Save</button>}
                {shownDescription != preset.Label && <button onClick={() => setName(preset.Guid, preset.Label)}>Cancel</button>}
              </div>

              <div className="delete">
                <button onClick={() => deletepreset(preset.Guid)}>Delete</button>
              </div>
            </div>
          }).reverse()
      }
  </div>
}