import React from 'react';

class Account extends React.Component {

  render() {
    return (
      <div className="account">
        {this.props.user && <div>
          Welcome, {this.props.user.username} | <a href="#" onClick={(e) => { e.preventDefault(); this.props.onLogout()}}>Logout</a>
        </div>}
        {!this.props.user && 
          <button onClick={() => this.props.onLogin()}>Login</button>}
      </div>
    );
  }
}

export default Account;